import { CreateMemoInput } from "API";
import { ReturnValue } from "components/common/forms/add-memo-form";
import { User } from "interfaces/user";

export const createNewMemo = (
  data: ReturnValue,
  user: User
): CreateMemoInput => {
  // !? data is actually Date but DB thinks it is string
  // @ts-ignore
  return {
    ...data,
    email: user.attributes.email,
    owner: user.username,
  };
};
