import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	AlertDialogProps,
	HStack,
} from "@chakra-ui/core";
import { FocusableElement } from "@chakra-ui/utils";
import React, { FC, ReactChildren } from "react";
import { Button } from "../common/button";

export type DialogProps = {
	alertDialogProps: Omit<AlertDialogProps, "children">;
	header?: string;
	body?: string;
	footer?: ReactChildren | JSX.Element;
	children?: never;
};

const Dialog: FC<DialogProps> = ({
	alertDialogProps,
	header,
	body,
	footer,
}) => {
	return (
		<AlertDialog motionPreset="scale" isCentered {...alertDialogProps}>
			<AlertDialogOverlay>
				<AlertDialogContent m={4}>
					{header && <AlertDialogHeader>{header}</AlertDialogHeader>}
					{body && <AlertDialogBody>{body}</AlertDialogBody>}
					{footer && <AlertDialogFooter>{footer}</AlertDialogFooter>}{" "}
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export type DeleteMemoDialogProps = {
	onCloseFocusRef?: React.RefObject<FocusableElement>;
	onClose(): void;
	onDelete(): void;
	isOpen: boolean;
	isLoading: boolean;
	id: string;
	owner: string;
};

export const DeleteMemoDialog: FC<DeleteMemoDialogProps> = ({
	onCloseFocusRef,
	onClose,
	isOpen,
	onDelete,
	isLoading,
}) => {
	const header = "Delete Memo?";
	const body =
		"Are you sure you want to delete this " +
		"memo? There wont be the way back!";
	const alertDialogProps = {
		leastDestructiveRef: onCloseFocusRef,
		closeOnEsc: true,
		isOpen,
		onClose,
	};
	const footer = (
		<HStack spacing={4}>
			<Button size="md" px={8} onClick={onClose} disabled={isLoading}>
				Neah
			</Button>
			<Button
				size="md"
				px={8}
				variant="outline"
				colorScheme="red"
				onClick={onDelete}
				{...{ isLoading }}
			>
				Yes
			</Button>
		</HStack>
	);

	return <Dialog {...{ header, body, footer, alertDialogProps }} />;
};
