import { Memo } from "interfaces/index";

export enum memoActionsType {
  memoRequest = "memo.request",
  memoRequestFail = "memo.request.fail",
  memoGetList = "memo.getList",
  memoAdd = "memo.add",
  memoDelete = "memo.delete",
  memoUpdate = "memo.update",
}
export type Actions =
  | { type: memoActionsType.memoRequest }
  | { type: memoActionsType.memoRequestFail; payload: Error[] }
  | { type: memoActionsType.memoGetList; payload: Memo[] }
  | { type: memoActionsType.memoAdd; payload: Memo }
  | { type: memoActionsType.memoUpdate; payload: Memo[] }
  | { type: memoActionsType.memoDelete; payload: Memo["id"] };
