import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  VStack,
} from "@chakra-ui/core";
import { Auth } from "aws-amplify";
import { Field, Form, Formik } from "formik";
import { FieldProps } from "formik/dist/Field";
import { useToastError } from "hooks/use-toast";
import React, { useState } from "react";
import {
  dispatchConfirmSignUp,
  dispatchForgotPassword,
  dispatchSignedIn,
  dispatchSignUP,
} from "utils/change-auth-state";
import * as Yup from "yup";
import { Button } from "components/common/button";
import { Input } from "components/common/input";
import { Span } from "./span";
import { Text } from "./text";
import { Wrapper } from "./wrapper";

export function SignInForm() {
  const [isLoading, setIsLoading] = useState(false);
  const toastError = useToastError();
  const validationSchema = Yup.object().shape({
    password: Yup.string().max(25, "Too Long").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const signIn = (values: { email: string; password: string }) => {
    setIsLoading(true);
    Auth.signIn(values.email, values.password)
      .then(() => {
        setIsLoading(false);
        dispatchSignedIn();
      })
      .catch((err) => {
        setIsLoading(false);
        toastError({ title: err.message });
        if (err.message?.toLowerCase().includes("user is not confirmed")) {
          return dispatchConfirmSignUp();
        }
      });
  };

  return (
    <Wrapper>
      <Heading size="md" pb={8}>
        Sign in to your account
      </Heading>
      <Formik
        initialValues={{
          password: "",
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={signIn}
      >
        <Form>
          <VStack spacing={8}>
            <Field name="email">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(form.errors.email && form.touched.email)}
                >
                  <FormLabel>Email address</FormLabel>
                  <Input
                    {...field}
                    placeholder="email"
                    type="email"
                    autoComplete="c-email"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    form.errors.password && form.touched.password
                  )}
                >
                  <FormLabel>Password</FormLabel>
                  <Input
                    {...field}
                    placeholder="password"
                    type="password"
                    autoComplete="c-pass"
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Text>
              Forgot your password?&nbsp;
              <Span onClick={dispatchForgotPassword}>Reset password</Span>
            </Text>
            <Flex
              flexDirection={{ base: "column-reverse", md: "row" }}
              alignItems="center"
              w="full"
            >
              <Text
                color="cyan.700"
                fontSize="md"
                cursor="pointer"
                onClick={dispatchSignUP}
              >
                Create&nbsp;Account
              </Text>
              <Button type="submit" width="full" isLoading={isLoading}>
                Sign In
              </Button>
            </Flex>
          </VStack>
        </Form>
      </Formik>
    </Wrapper>
  );
}
