import { GraphQLResult } from "@aws-amplify/api-graphql";
import { addMemoFail, addMemoRequest } from "actions/memo-actions";
import { CreateMemoMutation } from "API";
import { API } from "aws-amplify";
import { ReturnValue } from "components/common/forms/add-memo-form";
import { createMemo } from "graphql/mutations";
import { createNewMemo } from "helpers/create-new-memo";
import { Actions } from "interfaces/memo-actions-types";
import { User } from "interfaces/user";
import { Dispatch } from "react";

type OnSubmitCreateMemo = {
  data: ReturnValue;
  dispatch: Dispatch<Actions>;
  user: User;
};

export const onSubmitCreateMemo = async (
  { data, user, dispatch }: OnSubmitCreateMemo,
  cb: () => void
) => {
  dispatch(addMemoRequest());
  try {
    const res = (await API.graphql({
      query: createMemo,
      variables: { input: createNewMemo(data, user) },
    })) as GraphQLResult<CreateMemoMutation>;
    cb();
    if (res.errors?.length) {
      console.error("WTF!?");
      dispatch(addMemoFail(res.errors));
      return;
    }
    return;
  } catch (err) {
    console.log(err);
    dispatch(addMemoFail([...(err as any)?.errors]));
  }
};
