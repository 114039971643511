import { Input as ChInput, InputProps } from "@chakra-ui/core";
import React from "react";

export function Input({ children, ...props }: InputProps) {
  return (
    <ChInput size="lg" {...props}>
      {children}
    </ChInput>
  );
}
