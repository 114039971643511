import { Text as ChText, TextProps } from "@chakra-ui/core";
import React from "react";

export function Text({ children, ...props }: TextProps) {
  return (
    <ChText
      w="full"
      color="gray.400"
      fontSize="sm"
      mr={{ base: 0 }}
      mt={{ base: 4, md: 0 }}
      textAlign={{ base: "center", md: "left" }}
      {...props}
    >
      {children}
    </ChText>
  );
}
