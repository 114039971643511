import { Button as ChButton, ButtonProps } from "@chakra-ui/core";
import React from "react";

export function Button({ children, ...props }: ButtonProps) {
	const style =
		(props.variant === "solid" || !props?.variant) && !props.colorScheme
			? {
					color: "white",
					boxShadow: "0 0 10px #00B5D888",
					_hover: { boxShadow: "0 0 13px #00B5D8dd" },
					colorScheme: "cyan",
			  }
			: {};
	return (
		<ChButton size="lg" {...style} {...props}>
			{children}
		</ChButton>
	);
}
