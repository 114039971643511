import { MemosStore } from "interfaces";
import { Actions, memoActionsType } from "interfaces/memo-actions-types";
import { Reducer } from "react";

export const memoReducer: Reducer<MemosStore, Actions> = (store, action) => {
  switch (action.type) {
    case memoActionsType.memoRequest:
      return { ...store, loading: true, errors: null };
    case memoActionsType.memoGetList:
      return {
        ...store,
        memos: action.payload,
        loading: false,
      };
    case memoActionsType.memoRequestFail:
      return {
        ...store,
        loading: false,
        errors: action.payload,
      };
    case memoActionsType.memoAdd:
      return {
        ...store,
        loading: false,
        memos: [...store.memos, action.payload],
        errors: null,
      };
    case memoActionsType.memoDelete:
      return {
        ...store,
        loading: false,
        memos: store.memos.filter((m) => m.id !== action.payload),
        errors: null,
      };
    default:
      return store;
  }
};
