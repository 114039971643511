const baseStylePopper = {
  w: "100%",
  maxW: "xs",
  zIndex: 10,
};

export const components = {
  Popover: {
    parts: ["popper"],
    baseStyle: {
      // @ts-ignore
      popper: ({ width }) => ({
        ...baseStylePopper,
        maxW: width ? width : baseStylePopper.maxW,
      }),
    },
  },
};
