import { HStack, VStack } from "@chakra-ui/core";
import { Button } from "components/common/button";
import { InputField, TextField } from "components/common/forms/input-field";
import { Formik, Form } from "formik";
import React from "react";

type ReturnValue = {
	name: string;
	category: string;
	date: Date;
	description: string;
};

type AddMemoFormProps = {
	onSubmit(d: ReturnValue): void;
	onCancel(): void;
	submitting: boolean;
	children?: never;
};

const AddMemoForm: React.FC<AddMemoFormProps> = ({
	onSubmit,
	submitting,
	onCancel,
}) => {
	const initialValues = {
		name: "",
		category: "Default",
		date: new Date(),
		description: "",
	};

	return (
		<Formik {...{ onSubmit, initialValues }}>
			<Form>
				<VStack spacing={4} mb={8}>
					{
						// todo: implement the static categories with a ability to add a new one
						/*<SelectField
                        name={"category"}
                        // placeholder="to be select"
                        label={"Category"}
                        isRequired
                        placeholder="Select category"
                        {...{ options} }}
                      />*/
					}
					<InputField
						name={"name"}
						placeholder="Change the pills"
						label={"Name"}
						isRequired
					/>
					<InputField
						name={"date"}
						label={"Date"}
						isRequired
						type="date"
						helperText="Set the event's end or count from date"
					/>
					<TextField name="description" label="Description" />
				</VStack>
				<HStack>
					<Button onClick={onCancel} variant="outline" isLoading={submitting}>
						Cancel{" "}
					</Button>
					<Button
						type="submit"
						colorScheme="green"
						variant="outline"
						isLoading={submitting}
						isFullWidth
					>
						+ Add{" "}
					</Button>
				</HStack>
			</Form>
		</Formik>
	);
};

export { AddMemoForm };
export type { ReturnValue, AddMemoFormProps };
