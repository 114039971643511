import { AuthState } from "@aws-amplify/ui-components";
import { Hub } from "aws-amplify";

export function changeAuthState(state: AuthState) {
  Hub.dispatch("UI Auth", {
    event: "AuthStateChange",
    message: state,
  });
}

export function dispatchSignedOut() {
  changeAuthState(AuthState.SignedOut);
}

export function dispatchSignIn() {
  changeAuthState(AuthState.SignIn);
}

export function dispatchSignedIn() {
  changeAuthState(AuthState.SignedIn);
}

export function dispatchConfirmSignUp() {
  changeAuthState(AuthState.ConfirmSignUp);
}

export function dispatchForgotPassword() {
  changeAuthState(AuthState.ForgotPassword);
}

export function dispatchSignUP() {
  changeAuthState(AuthState.SignUp);
}
