import { Box, BoxProps } from "@chakra-ui/core";
import React from "react";

export function Wrapper({ children, ...props }: BoxProps) {
  return (
    <Box
      rounded="lg"
      p={12}
      border="1px solid"
      borderColor="gray.200"
      boxShadow="md"
      maxW="35rem"
      minW={{ base: "auto", md: "29rem" }}
      mx="auto"
      display="inline-table"
      {...props}
    >
      {children}
    </Box>
  );
}
