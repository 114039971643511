import { Auth, Hub } from "aws-amplify";
import { Authenticator } from "components/authenticator";
import { Container } from "components/common/container";
import { Dashboard } from "components/dashboard";
import { UserContext } from "context";
import { User } from "interfaces/user";
import React, { useEffect, useState } from "react";
import { isProd } from "utils/isProd";

function App() {
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    if (isProd) {
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
    }
  });

  // check if the user logged in on the first load
  useEffect(() => {
    checkUser();
  }, []);

  // check user on every authentication status change
  useEffect(() => {
    Hub.listen("auth", () => {
      checkUser();
    });
    return Hub.listen("auth", () => void 0);
  }, []);

  async function checkUser() {
    try {
      const user = await Auth.currentUserInfo();
      setUser(user);
    } catch (err) {
      // sign out
      setUser(undefined);
    }
  }

  return (
    <UserContext.Provider value={user!}>
      <Container
        p={0}
        flexDirection="column"
        justifyContent="center"
        w="100vw"
        h="100vh"
      >
        <Authenticator>{user && <Dashboard />}</Authenticator>
      </Container>
    </UserContext.Provider>
  );
}

export default App;
