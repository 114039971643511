import { Box } from "@chakra-ui/core";
import React from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import { SwipeAction, TrailingActions } from "react-swipeable-list";

export const TrailingAction = (p: { onClic(): void }) => (
	<TrailingActions>
		<SwipeAction onClick={p.onClic}>
			<Box
				px={8}
				bg="tomato"
				display="flex"
				justifyContent="center"
				alignItems="center"
				borderTopRightRadius={4}
				borderBottomRightRadius={4}
			>
				<DeleteIcon color="white" w={6} h={6} />
			</Box>
		</SwipeAction>
	</TrailingActions>
);
