/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMemo = /* GraphQL */ `
  subscription OnCreateMemo($owner: String!) {
    onCreateMemo(owner: $owner) {
      id
      name
      description
      category
      date
      email
      notify
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMemo = /* GraphQL */ `
  subscription OnUpdateMemo($owner: String!) {
    onUpdateMemo(owner: $owner) {
      id
      name
      description
      category
      date
      email
      notify
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMemo = /* GraphQL */ `
  subscription OnDeleteMemo($owner: String!) {
    onDeleteMemo(owner: $owner) {
      id
      name
      description
      category
      date
      email
      notify
      owner
      createdAt
      updatedAt
    }
  }
`;
