import { ButtonProps } from "@chakra-ui/core";
import { Auth } from "aws-amplify";
import { Button } from "components/common/button";
import React from "react";

type LogOffButtonProps = {
  children?: never;
} & ButtonProps;

const LogOffButton: React.FC<LogOffButtonProps> = (props) => {
  return (
    <Button
      onClick={() => Auth.signOut()}
      variant="ghost"
      colorScheme="cyan"
      {...props}
    >
      Sign out
    </Button>
  );
};

export default LogOffButton;
