import { useToast, UseToastOptions } from "@chakra-ui/core";
import { useCallback } from "react";

export type Toast = (options?: UseToastOptions) => string | number | undefined;

export const useToastHook =
	(_options: UseToastOptions = {}) =>
	(): Toast => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const toast = useToast();

		const defaultOptions: UseToastOptions = {
			title: "",
			description: "",
			duration: 5000,
			status: "info",
			isClosable: true,
			position: "top",
			..._options,
		};

		const _toast = (options: UseToastOptions = {}) =>
			toast({
				...defaultOptions,
				...options,
			});

		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useCallback(_toast, []);
	};

export const useToastError = useToastHook({ status: "error" });
export const useToastWarning = useToastHook({ status: "warning" });
export const useToastInfo = useToastHook({ status: "info" });
export const useToastSuccess = useToastHook({ status: "success" });
