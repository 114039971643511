import { extendTheme } from "@chakra-ui/core";
import { colors } from "theme/colors";
import { components } from "theme/components";

const overrides = {
  components,
  colors,
};

export default extendTheme(overrides);
