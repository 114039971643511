import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
  DeleteMemoInput,
  DeleteMemoMutationVariables,
  ListMemosQuery,
} from "API";
import { API } from "aws-amplify";
import { deleteMemo as query } from "graphql/mutations";

export const deleteMemoHelper = (input: DeleteMemoInput, cb: Function) => {
  const variables: DeleteMemoMutationVariables = { input };

  try {
    (API.graphql({
      query,
      variables,
    }) as Promise<GraphQLResult<ListMemosQuery>>)
      .then(() => {
        cb();
      })
      .catch((e) => {
        console.error(e);
        cb(e);
      });
  } catch (e) {
    console.error(e);
    cb(e);
  }
};
