import { AuthState } from "@aws-amplify/ui-components";
import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { dispatchSignedIn, dispatchSignedOut } from "utils/change-auth-state";
import { ConfirmSignUpForm } from "./confirm-sign-up-form";
import { ForgotPasswordForm } from "./forgot-password-form";
import { SignInForm } from "./sign-in-form";
import { SignUpForm } from "./sign-up-form";

export const Authenticator: React.FC = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>();

  useEffect(() => {
    setAuthUIStateListener(setAuthState);
    setSignOutListener(setAuthState);
    checkUser();

    return removeListeners();
  }, []);

  switch (authState) {
    case AuthState.Loading:
    case AuthState.SignedOut:
    case AuthState.SignIn:
      return <SignInForm />;
    case AuthState.SignUp:
      return <SignUpForm />;
    case AuthState.ConfirmSignUp:
      return <ConfirmSignUpForm />;
    case AuthState.ForgotPassword:
      return <ForgotPasswordForm />;
    case AuthState.SignedIn:
      return <>{children}</>;
    default:
      return null;
  }
};

function setSignOutListener(
  cb: React.Dispatch<React.SetStateAction<AuthState | undefined>>
) {
  Hub.listen("auth", (e) => {
    if (e.payload.event === "signOut") cb(AuthState.SignedOut);
  });
}

function setAuthUIStateListener(
  cb: React.Dispatch<React.SetStateAction<AuthState | undefined>>
) {
  Hub.listen("UI Auth", (e) => {
    if (e.payload.event === "AuthStateChange")
      cb(e.payload.message as AuthState);
  });
}

function removeListeners() {
  Hub.listen("auth", () => void 0);
  Hub.listen("UI Auth", () => void 0);
}

async function checkUser() {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    user && dispatchSignedIn();
  } catch (err) {
    // sign out
    dispatchSignedOut();
  }
}
