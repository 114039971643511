import {
  Container as ChContainer,
  ContainerProps,
  useBreakpointValue,
} from "@chakra-ui/core";
import React from "react";

/**
 * @description Used to constrain a content's width to the current breakpoint, while keeping it fluid.
 * @param children
 * @param block
 * @param props
 * @constructor
 */
export function Container({
  children,
  block = false,
  ...props
}: ContainerProps & { block?: boolean }) {
  const breakpoint = useBreakpointValue({ base: "md", lg: "lg", xl: "xl" });
  const maxW = block ? "100%" : breakpoint;

  return (
    <ChContainer display="flex" {...{ maxW, ...props }}>
      {children}
    </ChContainer>
  );
}
