import { Memo } from "interfaces";
import { Actions, memoActionsType } from "interfaces/memo-actions-types";

export const getMemosListRequest: () => Actions = () => ({
  type: memoActionsType.memoRequest,
});
export const addMemoRequest: () => Actions = () => ({
  type: memoActionsType.memoRequest,
});
export const getMemosListSuccess: (memos: Memo[]) => Actions = (payload) => ({
  type: memoActionsType.memoGetList,
  payload,
});
export const addMemo: (memo: Memo) => Actions = (payload) => ({
  type: memoActionsType.memoAdd,
  payload,
});
export const deleteMemo: (id: Memo["id"]) => Actions = (payload) => ({
  type: memoActionsType.memoDelete,
  payload,
});
export const getMemosListFail: (errors: Error[]) => Actions = (payload) => ({
  type: memoActionsType.memoRequestFail,
  payload,
});
export const addMemoFail: (err: Error[]) => Actions = (payload) => ({
  type: memoActionsType.memoRequestFail,
  payload,
});
