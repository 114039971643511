import {
	List,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Text,
	useDisclosure,
	VStack,
} from "@chakra-ui/core";

import {
	addMemo,
	deleteMemo,
	getMemosListFail,
	getMemosListRequest,
	getMemosListSuccess,
} from "actions/memo-actions";
import { API } from "aws-amplify";
import { Button } from "components/common/button";
import { Container } from "components/common/container";
import {
	AddMemoForm,
	ReturnValue,
} from "components/common/forms/add-memo-form";
import LayoutHeader from "components/common/layout-header";
import { NoContent } from "components/common/no-content";
import { MemoItem } from "components/memo";
import { UserContext } from "context";
import {
	onCreateMemo,
	onDeleteMemo,
	onUpdateMemo,
} from "graphql/subscriptions";
import { getMemos } from "helpers/get-memos";
import { onSubmitCreateMemo } from "helpers/on-submit-create-memo";
import {
	useToastError,
	useToastSuccess,
	useToastWarning,
} from "hooks/use-toast";
import type { Memo, MemosStore } from "interfaces";
import React, { FC, useContext, useEffect, useReducer } from "react";

import { SwipeableList, SwipeableListItem, Type } from "react-swipeable-list";

import "react-swipeable-list/dist/styles.css";

import { memoReducer } from "reducers/memo-reducer";
import { deleteMemoHelper } from "../../helpers/delete-memo-helper";
import { DeleteMemoDialog, DeleteMemoDialogProps } from "./delete-memo-dialog";
import { TrailingAction } from "./trailing-action";

type DashboardProps = {
	children?: never;
};

const initialState: MemosStore = {
	memos: [] as Memo[],
	errors: null,
	loading: false,
};

export const Dashboard: FC<DashboardProps> = () => {
	const user = useContext(UserContext);
	const toastWarning = useToastWarning();
	const toastError = useToastError();
	const toastSuccess = useToastSuccess();

	const [deleteDialog, setDeleteDialog] = React.useState<DeleteMemoDialogProps>(
		{
			id: "",
			owner: "",
			isOpen: false,
			isLoading: false,
			onClose() {
				setDeleteDialog((state) => ({ ...state, isOpen: false }));
			},
			onDelete() {},
		}
	);

	const {
		isOpen: addOpen,
		onOpen: addOnOpen,
		onClose: addOnClose,
	} = useDisclosure();

	const [{ errors, loading, memos }, dispatch] = useReducer(
		memoReducer,
		initialState
	);

	const handleOnSubmit = (data: ReturnValue) => {
		onSubmitCreateMemo({ data, dispatch, user }, addOnClose);
	};

	// show errors
	useEffect(() => {
		if (errors?.length)
			errors.forEach((e) => {
				toastError({ title: e.message });
			});
	}, [errors, toastError]);

	//region Loads all memos on init
	useEffect(() => {
		dispatch(getMemosListRequest());
		getMemos()
			.then(({ data, errors }) => {
				if (errors?.length) return dispatch(getMemosListFail(errors));
				if (data?.listMemos?.items) {
					const items =
						data.listMemos?.items?.sort((a, b) =>
							a?.date! > b?.date! ? 1 : -1
						) ?? [];

					dispatch(getMemosListSuccess(items as Memo[]));
				}
			})
			.catch(({ errors }) => {
				dispatch(getMemosListFail(errors));
				console.log(errors);
			});
	}, [toastWarning, user]);
	//endregion

	//region Subscribe on memo delete
	useEffect(() => {
		const subscription = API.graphql(
			{ query: onDeleteMemo, variables: { owner: user.username } }
			// @ts-ignore
		).subscribe({
			// @ts-ignore
			next: ({ value }) => {
				dispatch(deleteMemo(value.data.onDeleteMemo.id));
			},
		});

		// Stop receiving data updates from the subscription
		return () => subscription.unsubscribe();
	}, [user.username]);
	//endregion

	//region Subscribe on memo create
	useEffect(() => {
		const subscription = API.graphql(
			{ query: onCreateMemo, variables: { owner: user.username } }
			// @ts-ignore
		).subscribe({
			// @ts-ignore
			next: ({ value }) => {
				dispatch(addMemo(value.data.onCreateMemo));
			},
		});

		// Stop receiving data updates from the subscription
		return () => subscription.unsubscribe();
	}, [user.username]);
	//endregion

	//todo:
	//region subscribe on memo modify
	useEffect(() => {
		const subscription = API.graphql(
			{ query: onUpdateMemo, variables: { owner: user.username } }
			// @ts-ignore
		).subscribe({
			// @ts-ignore
			next: ({ provider, value }) => {
				console.log("update");
				console.log({ provider, value });
			},
		});

		// Stop receiving data updates from the subscription
		return subscription.unsubscribe();
	}, [user.username]);
	//endregion

	const stats = (
		<Text size="xs" color="gray.500">
			w: 1 | m: 3 | y: 30
		</Text>
	);

	const handleDelete = (id: string, owner: string) => () => {
		setDeleteDialog((state) => ({
			...state,
			onDelete: () => {
				setDeleteDialog((prevState) => ({ ...prevState, isLoading: true }));
				deleteMemoHelper({ id, owner }, (e?: Error) => {
					if (e) toastError({ title: e.message });
					if (!e) {
						toastSuccess({ title: "Memo has been successfully deleted" });
					}
					setDeleteDialog((state) => ({
						...state,
						isOpen: false,
						isLoading: false,
					}));
				});
			},
			isOpen: true,
		}));
	};

	// handleDelete({ id, owner })}

	return (
		<>
			<Container flexDirection="column" h="100vh" overflow="auto">
				<LayoutHeader />
				<VStack>
					{loading && !memos?.length ? (
						<Spinner
							thickness="4px"
							speed="0.65s"
							emptyColor="gray.200"
							color="cyan.500"
							size="xl"
						/>
					) : memos?.length ? (
						<List spacing={3} maxWidth="360px" w={{ base: "100%", md: "90vw" }}>
							<Button
								onClick={addOnOpen}
								size="lg"
								variant="ghost"
								colorScheme="green"
							>
								+ Memorise
							</Button>
							<ListItem
								pl=".5rem"
								position="relative"
								_before={{
									content: `""`,
									position: "absolute",
									left: "-.5rem",
									top: 0,
									bottom: 0,
									height: "100%",
									width: 0,
									border: "3px solid #ef00ff",
									borderRadius: "3px",
								}}
							>
								{stats}
							</ListItem>
							<SwipeableList type={Type.IOS} fullSwipe>
								{memos.map((m, i) => {
									return (
										<SwipeableListItem
											key={m.id}
											trailingActions={
												<TrailingAction onClic={handleDelete(m.id!, m.owner)} />
											}
										>
											<MemoItem data={m} notLast={i < memos.length - 1} />
										</SwipeableListItem>
									);
								})}
							</SwipeableList>
							<DeleteMemoDialog {...deleteDialog} />
						</List>
					) : (
						<NoContent
							onClick={addOnOpen}
							role="button"
							aria-label="No Content"
						/>
					)}
				</VStack>
			</Container>
			<Modal isOpen={addOpen} onClose={addOnClose}>
				<ModalOverlay />
				<ModalContent m={8}>
					<ModalHeader>Create new Memo</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<AddMemoForm
							onSubmit={handleOnSubmit}
							submitting={loading}
							onCancel={addOnClose}
						/>
					</ModalBody>
					<ModalFooter />
				</ModalContent>
			</Modal>
		</>
	);
};
