import { GraphQLResult } from "@aws-amplify/api-graphql";
import { ListMemosQuery } from "API";
import { API } from "aws-amplify";
import { listMemos } from "graphql/queries";

export async function getMemos() {
  return API.graphql({
    query: listMemos,
  }) as GraphQLResult<ListMemosQuery>;
}
