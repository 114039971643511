import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  VStack,
} from "@chakra-ui/core";
import { Auth, Hub } from "aws-amplify";
import { Field, Form, Formik } from "formik";
import { FieldProps } from "formik/dist/Field";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useToastError } from "hooks/use-toast";
import { dispatchSignedOut, dispatchSignIn } from "utils/change-auth-state";
import { Button } from "components/common/button";
import { Input } from "components/common/input";
import { Span } from "./span";
import { Text } from "./text";
import { Wrapper } from "./wrapper";

export function ForgotPasswordForm() {
  const [user, setUser] = useState<string | null>(null);

  useEffect(() => {
    Hub.listen("auth", (e) => {
      if (
        e.payload.data.authenticationFlowType === "USER_SRP_AUTH" &&
        e.payload.data.username &&
        e.payload.message?.includes("has initiated forgot password flow")
      )
        setUser(e.payload.data.username);
    });
    return () => {
      Hub.listen("auth", () => {});
    };
  }, []);

  const StartPasswordReset = () => {
    const [isLoading, setIsLoading] = useState(false);
    const toastError = useToastError();

    const validationSchema = Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Required"),
    });

    const handleSubmit = ({ email }: { email: string }) => {
      setIsLoading(true);
      Auth.forgotPassword(email)
        // .then((_res) => {
        //   setIsLoading(false);
        // })
        .catch((err) => {
          setIsLoading(false);
          // @ts-ignore
          toastError({ title: err.message });
        });
    };

    return (
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <VStack spacing={8}>
            <Field name="email">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(form.errors.email && form.touched.email)}
                >
                  <FormLabel>Email address</FormLabel>
                  <Input
                    {...field}
                    placeholder="email"
                    type="email"
                    autoComplete="n-email"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex
              flexDirection={{ base: "column-reverse", md: "row" }}
              alignItems="center"
              w="full"
            >
              <Text mr={{ md: 4 }}>
                Back&nbsp;to&nbsp;
                <Span onClick={dispatchSignIn}>Sign&nbsp;In</Span>
              </Text>
              <Button width="full" type="submit" isLoading={isLoading}>
                Send code
              </Button>
            </Flex>
          </VStack>
        </Form>
      </Formik>
    );
  };

  const PasswordResetSubmit = ({ username }: { username: string }) => {
    const [isLoading, setIsLoading] = useState(false);
    const toastError = useToastError();

    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .label("password")
        .min(8, "Too Short!")
        .max(25, "Too Long!")
        .required("Required"),
      code: Yup.string().required("Required"),
      "c-password": Yup.string()
        .required("Required")
        .label("c-password")
        .test("passwords-match", "Passwords must match ya", function (value) {
          return this.parent.password === value;
        }),
    });

    const handleSubmit = ({
      password,
      code,
    }: {
      password: string;
      code: string;
    }) => {
      setIsLoading(true);
      Auth.forgotPasswordSubmit(username, code, password)
        .then((_user) => {
          setIsLoading(false);
          dispatchSignedOut();
        })
        .catch((err) => {
          setIsLoading(false);
          // @ts-ignore
          toastError({ title: err.message });
        });
    };

    return (
      <Formik
        initialValues={{
          password: "",
          "c-password": "",
          code: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <VStack spacing={8}>
            <Field name="code">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(form.errors.code && form.touched.code)}
                >
                  <FormLabel>Confirmation Code</FormLabel>
                  <Input {...field} placeholder="code" autoComplete="code" />
                  <FormErrorMessage>{form.errors.code}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    form.errors.password && form.touched.password
                  )}
                >
                  <FormLabel>Compose new password</FormLabel>
                  <Input
                    {...field}
                    placeholder="password"
                    type="password"
                    autoComplete="n-pass"
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="c-password">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    form.errors["c-password"] && form.touched["c-password"]
                  )}
                >
                  <FormLabel>Confirm your password</FormLabel>
                  <Input
                    {...field}
                    placeholder="password"
                    type="password"
                    autoComplete="cn-pass"
                  />
                  <FormErrorMessage>
                    {form.errors["c-password"]}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex
              flexDirection={{ base: "column-reverse", md: "row" }}
              alignItems="center"
              w="full"
            >
              <Text
                color="cyan.700"
                fontSize="md"
                cursor="pointer"
                onClick={dispatchSignIn}
              >
                Go&nbsp;to&nbsp;Sign&nbsp;In
              </Text>
              <Button type="submit" width="full" isLoading={isLoading}>
                Reset Password
              </Button>
            </Flex>
          </VStack>
        </Form>
      </Formik>
    );
  };

  return (
    <Wrapper>
      <Heading size="md" pb={8}>
        Reset your password
      </Heading>
      {user ? <PasswordResetSubmit username={user} /> : <StartPasswordReset />}
    </Wrapper>
  );
}
