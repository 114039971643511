import { Heading, Box, BoxProps } from "@chakra-ui/core";
import { Text } from "components/authenticator/text";
import { ReactComponent as NoData } from "img/undraw_No_data_re_kwbl.svg";
import React from "react";

export const NoContent: React.FC<BoxProps> = (props) => {
	return (
		<Box {...props} display="flex" alignItems="center" flexDirection="column">
			<Heading size="lg" mb={16} align="center">
				Yo, click here to create your first{" "}
				<Text size="3xl" fontSize={undefined} display="inline" color="cyan.400">
					Memo
				</Text>
			</Heading>
			<NoData height="auto" width="63vmin" />
		</Box>
	);
};
