import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  Textarea,
} from "@chakra-ui/core";

import { FastField, FieldProps } from "formik";
import React, { ReactElement } from "react";
import { Input } from "components/common/input";

type InputFieldProps = {
  children?: never;
  name: string;
  autoComplete?: string;
  placeholder?: string;
  label?: ReactElement | string;
  isRequired?: boolean;
  type?: string;
  helperText?: string;
};

export const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    name,
    autoComplete,
    placeholder,
    label,
    type,
    isRequired,
    helperText,
  } = props;
  return (
    <FastField {...{ name }}>
      {({ field, form }: FieldProps) => (
        <FormControl
          isInvalid={Boolean(form.errors[name] && form.touched[name])}
          {...{ isRequired }}
        >
          {label ? <FormLabel>{label}</FormLabel> : null}
          <Input {...field} {...{ autoComplete, placeholder, type }} />
          {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  );
};

type SelectFieldProps = {
  children?: never;
  name: string;
  autoComplete?: string;
  placeholder?: string;
  type?: string;
  label?: ReactElement | string;
  isRequired?: boolean;
  options?: { label: string; value: string }[];
};

export const SelectField: React.FC<SelectFieldProps> = (props) => {
  const {
    name,
    options,
    autoComplete,
    placeholder,
    type,
    label,
    isRequired,
  } = props;
  return (
    <FastField {...{ name }}>
      {({ field, form }: FieldProps) => (
        <FormControl
          isInvalid={Boolean(form.errors[name] && form.touched[name])}
          {...{ isRequired }}
        >
          {label ? <FormLabel>{label}</FormLabel> : null}
          <Select
            {...field}
            {...{ autoComplete, placeholder, type }}
          >
            {options?.map(({ label, value }, index) => {
              return (
                <option value={value} key={index}>
                  {label}
                </option>
              );
            })}
          </Select>

          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  );
};

type TextFieldProps = {
  children?: never;
  name: string;
  autoComplete?: string;
  placeholder?: string;
  label?: ReactElement | string;
  isRequired?: boolean;
};

export const TextField: React.FC<TextFieldProps> = (props) => {
  const { name, autoComplete, placeholder, label, isRequired } = props;

  return (
    <FastField {...{ name }}>
      {({ field, form }: FieldProps) => (
        <FormControl
          isInvalid={Boolean(form.errors[name] && form.touched[name])}
          {...{ isRequired }}
        >
          {label ? <FormLabel>{label}</FormLabel> : null}
          <Textarea
            {...field}
            {...{ autoComplete, placeholder }}
            placeholder="Change the pills in the wall-clock"
          />
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  );
};
