import { Flex } from "@chakra-ui/core";
import LogOffButton from "components/common/log-off-button";
import React from "react";

type LayoutHeaderProps = {
  children?: never;
};

const LayoutHeader: React.FC<LayoutHeaderProps> = () => {
  return (
    <Flex as="nav" align="center" justifyContent="space-between" py={4}>
      <LogOffButton size="sm" ml="auto" />
    </Flex>
  );
};

export default LayoutHeader;
