/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMemo = /* GraphQL */ `
  query GetMemo($id: ID!, $owner: String!) {
    getMemo(id: $id, owner: $owner) {
      id
      name
      description
      category
      date
      email
      notify
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listMemos = /* GraphQL */ `
  query ListMemos(
    $id: ID
    $owner: ModelStringKeyConditionInput
    $filter: ModelMemoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMemos(
      id: $id
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        category
        date
        email
        notify
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
