import { TextProps } from "@chakra-ui/core";
import React from "react";
import { Text } from "./text";

export function Span({ children, ...props }: TextProps) {
  return (
    <Text
      as="span"
      display="inline"
      color="cyan.700"
      cursor="pointer"
      {...props}
    >
      {children}
    </Text>
  );
}
