import { Collapse, ListIcon, ListItem, Text } from "@chakra-ui/core";

import { StarIcon } from "@chakra-ui/icons";

import { CreateMemoInput } from "API";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { capitalize } from "lodash";
import React from "react";

dayjs.extend(relativeTime);

type MemoProps = {
	data: CreateMemoInput | null;
	notLast: boolean;
	children?: never;
};

export const MemoItem: React.FC<MemoProps> = ({ data, notLast }) => {
	const [descriptionVisible, setDescriptionVisible] = React.useState(false);

	if (!data) return null;

	const daysAmount = dayjs().diff(dayjs(data.date!), "day");
	const passed = daysAmount < 0;
	const date = dayjs(data.date ?? "").format("DD-MM-YYYY");
	const time = capitalize(dayjs(data.date ?? "").fromNow());

	const toggleDescription = () => setDescriptionVisible((prev) => !prev);

	return (
		<>
			<ListItem
				sx={{
					borderBottom: "1px solid",
					borderColor: notLast ? "gray.100" : "transparent",
					width: "100%",
					py: 2,
				}}
			>
				<Text fontWeight={700}>
					<ListIcon as={StarIcon} color={passed ? "green.400" : "tomato"} />
					{data.name}
				</Text>
				{data.description && (
					<Collapse
						style={{ cursor: "pointer" }}
						startingHeight={24}
						in={descriptionVisible}
						onClick={toggleDescription}
					>
						{data.description}
					</Collapse>
				)}
				<Text fontSize="xs" color="gray.500" fontStyle="italic">
					{date}
				</Text>
				<Text>{time}</Text>
			</ListItem>
		</>
		/* <>
		<Stat
			border="1px solid"
			p={ 4 }
			borderColor="gray.200"
			rounded="lg"
			cursor="pointer"
			position="relative"
			w={ {base: "full", md: "50%"} }
			boxShadow="md"
			_hover={ {boxShadow: "0 5px 10px  #888a"} }
			transition="box-shadow 300ms"
			onMouseEnter={ setMouseEnter }
			onMouseLeave={ setMouseLeave }
			// onClick={}
		>
			<StatLabel>{ data.name }</StatLabel>
			<StatNumber>{ Math.abs(daysAmount) }</StatNumber>
			<StatArrow { ...{type} } position="absolute" top={ 4 } right={ 4 } />
			<StatHelpText>{ data.description }</StatHelpText>
			<HStack position="absolute" bottom={ 4 } right={ 2 } spacing={ 0 }>
				<IconButton
					aria-label="Delete memo"
					icon={ <DeleteIcon /> }
					variant="link"
					onClick={ handleDelete }
					_hover={ {color: "red.500"} }
				/>
				<IconButton
					aria-label="Edit memo"
					icon={ <EditIcon /> }
					variant="link"
					onClick={ handleEdit }
				/>
			</HStack>
		</Stat>
		<DeleteMemoDialog { ...deleteDialog } />
	</>*/
	);
};
