import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  VStack,
} from "@chakra-ui/core";
import { Auth } from "aws-amplify";
import { Button } from "components/common/button";
import { Input } from "components/common/input";
import { Field, Form, Formik } from "formik";
import { FieldProps } from "formik/dist/Field";
import { useToastError } from "hooks/use-toast";
import React, { useState } from "react";
import { dispatchConfirmSignUp, dispatchSignIn } from "utils/change-auth-state";
import * as Yup from "yup";
import { Text } from "./text";
import { Wrapper } from "./wrapper";

export function SignUpForm() {
  const [isLoading, setIsLoading] = useState(false);
  const toastError = useToastError();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .label("password")
      .min(8, "Too Short!")
      .max(25, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    "c-password": Yup.string()
      .required("Required")
      .label("c-password")
      .test("passwords-match", "Passwords must match ya", function (value) {
        return this.parent.password === value;
      }),
  });

  const signUp = ({
    email: username,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    Auth.signUp({ username, password, attributes: { email: username } })
      .then((_user) => {
        setIsLoading(false);
        dispatchConfirmSignUp();
      })
      .catch((err) => {
        setIsLoading(false);
        toastError({ title: err.message });
      });
  };

  return (
    <Wrapper>
      <Heading size="md" pb={8}>
        Create a new account
      </Heading>
      <Formik
        initialValues={{
          password: "",
          "c-password": "",
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={signUp}
      >
        <Form>
          <VStack spacing={8}>
            <Field name="email">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(form.errors.email && form.touched.email)}
                >
                  <FormLabel>Email address</FormLabel>
                  <Input
                    {...field}
                    placeholder="email"
                    type="email"
                    autoComplete="n-email"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    form.errors.password && form.touched.password
                  )}
                >
                  <FormLabel>Compose your password</FormLabel>
                  <Input
                    {...field}
                    placeholder="password"
                    type="password"
                    autoComplete="n-pass"
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="c-password">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    form.errors["c-password"] && form.touched["c-password"]
                  )}
                >
                  <FormLabel>Confirm your password</FormLabel>
                  <Input
                    {...field}
                    placeholder="confirm"
                    type="password"
                    autoComplete="cn-pass"
                  />
                  <FormErrorMessage>
                    {form.errors["c-password"]}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex
              flexDirection={{ base: "column-reverse", md: "row" }}
              alignItems="center"
              w="full"
            >
              <Text
                color="cyan.700"
                fontSize="md"
                cursor="pointer"
                onClick={dispatchSignIn}
              >
                Go&nbsp;to&nbsp;Sign&nbsp;In
              </Text>
              <Button type="submit" width="full" isLoading={isLoading}>
                Sign Up
              </Button>
            </Flex>
          </VStack>
        </Form>
      </Formik>
    </Wrapper>
  );
}
