import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  VStack,
} from "@chakra-ui/core";
import { Auth } from "aws-amplify";
import { Button } from "components/common/button";
import { Input } from "components/common/input";
import { Field, Form, Formik } from "formik";
import { FieldProps } from "formik/dist/Field";
import { useToastError } from "hooks/use-toast";
import React, { useState } from "react";
import { dispatchSignIn } from "utils/change-auth-state";
import * as Yup from "yup";
import { Span } from "./span";
import { Text } from "./text";
import { Wrapper } from "./wrapper";

export function ConfirmSignUpForm() {
  const [isLoading, setIsLoading] = useState(false);
  const toastError = useToastError();

  const validationSchema = Yup.object().shape({
    code: Yup.string().max(25, "Too Long!").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleSubmit = ({ code, email }: { code: string; email: string }) => {
    setIsLoading(true);
    Auth.confirmSignUp(email, code)
      .then((_res) => {
        setIsLoading(false);
        dispatchSignIn();
      })
      .catch((err) => {
        setIsLoading(false);
        toastError({ title: err.message });
      });
  };

  const resendCode = () => {
    toastError({
      title: "hmm :/",
      description:
        "You'd better look carefully cause this feature doesnt work yet",
      // todo: implement resend code
    });
  };

  return (
    <Wrapper>
      <Heading size="md" pb={8}>
        Confirm your email address
      </Heading>
      <Formik
        initialValues={{ code: "", email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <VStack spacing={8}>
            <Field name="email">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(form.errors.email && form.touched.email)}
                >
                  <FormLabel>Email address</FormLabel>
                  <Input
                    {...field}
                    placeholder="email"
                    type="email"
                    autoComplete="n-email"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="code">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(form.errors.code && form.touched.code)}
                >
                  <FormLabel>Confirmation code</FormLabel>
                  <Input
                    {...field}
                    placeholder="code"
                    type="text"
                    autoComplete="c-code"
                  />
                  <FormErrorMessage>{form.errors.code}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex
              flexDirection={{ base: "column-reverse", md: "row" }}
              alignItems="center"
              w="full"
            >
              <Text mr={{ md: 4 }}>
                Lost&nbsp;code?&nbsp;
                <Span onClick={resendCode}>Resend&nbsp;code</Span>
              </Text>
              <Button width="full" type="submit" isLoading={isLoading}>
                Confirm
              </Button>
            </Flex>
          </VStack>
        </Form>
      </Formik>
    </Wrapper>
  );
}
